* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

h3 {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-weight: normal;
    margin-top: 8px;
    text-align: center;
}

label {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-weight: normal;
    margin-top: 8px;
    font-size: 14px;
}

button:hover {
    border: 1px solid white;
    cursor: pointer;
}

button {
    color: #fff;
    border: transparent;
    border-radius: 30px;
    padding: 16px;
}

.container {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 0;
}

.btn-download-app {
    font-size: 18px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    width: 140px;
}

.btn-old-version {
    font-size: 12px;
    font-family: "Poppins", sans-serif;
    background-color: transparent;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.logo-conecta {
    max-width: 75%;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
}

.logo-conecta img {
    width: 100%;
    height: auto;
    object-fit: contain;
}

.center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo-footer {
    align-items: center;
    padding: 8px;
}

.logo-footer img {
    height: 32px;
    object-fit: contain;
}


@media screen and (min-width: 768px) {
    .logo-conecta {
        max-width: 50%;
    }

    label {
        font-size: 20px;
    }
}
