* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

h3 {
  color: #fff;
  text-align: center;
  margin-top: 8px;
  font-family: Poppins, sans-serif;
  font-weight: normal;
}

label {
  color: #fff;
  margin-top: 8px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: normal;
}

button:hover {
  cursor: pointer;
  border: 1px solid #fff;
}

button {
  color: #fff;
  border: #0000;
  border-radius: 30px;
  padding: 16px;
}

.container {
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  display: flex;
}

.btn-download-app {
  text-shadow: 2px 2px 4px #00000080;
  width: 140px;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-weight: 600;
}

.btn-old-version {
  text-shadow: 2px 2px 4px #00000080;
  background-color: #0000;
  font-family: Poppins, sans-serif;
  font-size: 12px;
}

.logo-conecta {
  flex-direction: column;
  max-width: 75%;
  max-height: 80vh;
  margin-top: 50px;
  display: flex;
}

.logo-conecta img {
  object-fit: contain;
  width: 100%;
  height: auto;
}

.center {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.logo-footer {
  align-items: center;
  padding: 8px;
}

.logo-footer img {
  object-fit: contain;
  height: 32px;
}

@media screen and (width >= 768px) {
  .logo-conecta {
    max-width: 50%;
  }

  label {
    font-size: 20px;
  }
}
/*# sourceMappingURL=index.427162b0.css.map */
